import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/D20MJH3LhXk"
    bibleGroupSrc="https://www.docdroid.net/LFW2bID/bible-group-homework-5-24-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/15600794"
  >
    <SEO title="Paul's Prayer for the People - Colossians" />
  </Layout>
)

export default SermonPost
